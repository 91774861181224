import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import backendHttpClient from '#/src/api/backendHttpClient';
import type { Workspace } from '#/src/types/Workspace';

export type WorkspaceQueryKey = [string, Workspace['id'] | null];

export const getWorkspaceQueryKey = (workspaceId: Workspace['id'] | null): WorkspaceQueryKey => [
  'workspace',
  workspaceId
];

export const getWorkspaceQueryUrl = (workspaceId: Workspace['id'] | null) =>
  `/workspace/${workspaceId}`;

export const useWorkspaceQuery = (workspaceId: Workspace['id'] | null, opts?: any) => {
  return useQuery<Workspace | null, AxiosError, Workspace | null, WorkspaceQueryKey>({
    queryKey: getWorkspaceQueryKey(workspaceId),
    queryFn: ({ queryKey }) =>
      backendHttpClient.get(getWorkspaceQueryUrl(queryKey[1] as Workspace['id'] | null)),
    enabled: Boolean(workspaceId),
    ...opts
  });
};

export const useWorkspaceMutation = () =>
  useMutation({
    mutationFn: ({
      workspaceId,
      workspaceData
    }: {
      workspaceId: string;
      workspaceData: Partial<Workspace>;
    }) => backendHttpClient.put<Workspace>(`/workspace/${workspaceId}`, workspaceData)
  });
