import { ResourcesConfig } from '@aws-amplify/core';

const domain =
  process.env.NEXT_PUBLIC_DOMAIN || process.env.NEXT_PUBLIC_VERCEL_URL || 'localhost:3000';

const config: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
      userPoolClientId: process.env.NEXT_PUBLIC_COGNITO_WEB_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: process.env.NEXT_PUBLIC_COGNITO_AUTHENTICATION_DOMAIN,
          scopes: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [`https://${domain}/oauth-callback`],
          redirectSignOut: [`https://${domain}/logout`],
          responseType: 'code'
        }
      }
    }
  }
};

export default config;
