'use client';

import { datadogRum } from '@datadog/browser-rum';
import { AnalyticsBrowser, EventProperties } from '@segment/analytics-next';
import { useFlag } from '@unleash/nextjs';
import { FetchUserAttributesOutput, fetchUserAttributes } from 'aws-amplify/auth';
import cookie from 'js-cookie';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import { useSessionWorkspaceId } from '#/src/context/session/hooks/useWorkspace';

interface AnalyticsClient extends AnalyticsBrowser {
  trackExperiment: (experimentName: string, enabled: boolean) => Promise<any>;
  trackAuth: (event: AuthEvent, params: { provider: AuthProvider }) => Promise<any>;
  identifyUser: (userAttributes: FetchUserAttributesOutput) => Promise<any>;
}

const AnalyticsContext = createContext<AnalyticsClient | undefined>(undefined);

type Props = {
  writeKey: string | undefined;
  children: React.ReactNode;
};

export const AnalyticsProvider = ({ children, writeKey }: Props) => {
  const workspaceId = useSessionWorkspaceId();

  const client = useMemo<AnalyticsBrowser>(() => {
    return AnalyticsBrowser.load({ writeKey: writeKey || 'NO_KEY' });
  }, [writeKey]);

  const track = useCallback(
    (event: string, properties: EventProperties) => {
      return client.track(event, { ...(properties || {}), $groupId: workspaceId });
    },
    [client, workspaceId]
  );

  const analytics = useMemo<AnalyticsClient>(() => {
    return {
      ...client,

      track,

      trackExperiment: (experimentName: string, enabled: boolean) =>
        track('experiment.play', { experimentName, enabled }),

      trackAuth: (event: AuthEvent, params: { provider: AuthProvider }) =>
        track(event, {
          fbp: cookie.get('_fbp'),
          fbc: cookie.get('_fbc'),
          ...params
        }),

      identifyUser: async (userAttributes: FetchUserAttributesOutput) => {
        if (!userAttributes.sub) {
          return;
        }

        await client.identify(userAttributes.sub, {
          lastName: userAttributes.family_name,
          firstName: userAttributes.given_name,
          email: userAttributes.email
        });

        await datadogRum.setUser({
          id: userAttributes.email,
          name: `${userAttributes.given_name} ${userAttributes.family_name}`,
          email: userAttributes.email
        });
      }
    } as AnalyticsClient;
  }, [track, client]);

  useEffect(() => {
    (async function () {
      try {
        const userAttributes = await fetchUserAttributes();

        await analytics.identifyUser(userAttributes);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <AnalyticsContext.Provider value={analytics}>
      <AnalyticsPageListener analytics={analytics} workspaceId={workspaceId} />
      {children}
    </AnalyticsContext.Provider>
  );
};

function AnalyticsPageListener(props: { analytics: AnalyticsClient; workspaceId: string | null }) {
  const analytics = props.analytics;
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    analytics?.page('Superads app', 'Page', {
      $groupId: props.workspaceId
    });
  }, [analytics, pathname, searchParams]);

  return null;
}

// Create an analytics hook that we can use with other components.
export const useAnalytics = () => {
  const result = React.useContext(AnalyticsContext);
  if (!result) {
    throw new Error('Context used outside of its Provider!');
  }
  return result;
};

export enum AuthEvent {
  SIGNUP = 'signup',
  LOGIN = 'login'
}

export enum AuthProvider {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook'
}

export const useIdentifyUser = () => {
  const analytics = useAnalytics();

  return async () => {
    try {
      const userAttributes = await fetchUserAttributes();

      await analytics.identifyUser(userAttributes);
    } catch (e) {
      console.error('User identification failed', e);
      throw e;
    }
  };
};

export const useTrackUserAuth = () => {
  const analytics = useAnalytics();

  return async (params: Record<string, any>) => {
    try {
      const resp = await fetch('/api/user/me', { method: 'PUT' });

      const authData = await resp.json();
      const authEvent = authData.isNewUser ? AuthEvent.SIGNUP : AuthEvent.LOGIN;

      await analytics.trackAuth(authEvent, params);
    } catch (e) {
      console.error('User authentication failed', e);
      throw e;
    }
  };
};

export const useFlagWithAnalytics = (flagName: string) => {
  const analytics = useAnalytics();

  const flag = useFlag(flagName);

  useEffect(() => {
    analytics.trackExperiment(flagName, flag);
  }, [flag]);

  return flag;
};
