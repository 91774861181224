import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

interface Headers {
  [key: string]: string;
}

type RequestOpts = {
  signal?: AxiosRequestConfig['signal'];
};

function baseClient(
  axiosInstance: AxiosInstance,
  method: 'get' | 'post' | 'put' | 'delete',
  path: string,
  data: any,
  opts?: RequestOpts
): Promise<any> {
  return axiosInstance[method](`/api${path}`, data, opts).then((response: AxiosResponse<any>) => {
    return response.data;
  });
}

interface SessionConfigConfig {
  headers: {
    [key: string]: string;
  };
}

function createBackendHttpClient() {
  const axiosInstance = axios.create({});

  return {
    get<V = any>(path: string, opts?: RequestOpts): Promise<V> {
      return baseClient(axiosInstance, 'get', path, opts);
    },
    post<V = any>(path: string, data: any, opts?: RequestOpts): Promise<V> {
      return baseClient(axiosInstance, 'post', path, data, opts);
    },
    put<V = any>(path: string, data: any, opts?: RequestOpts): Promise<V> {
      return baseClient(axiosInstance, 'put', path, data, opts);
    },
    del<V = any>(path: string, opts?: RequestOpts): Promise<V> {
      return baseClient(axiosInstance, 'delete', path, opts);
    },
    logout() {
      //axiosInstance.interceptors.request.eject(sessionInterceptorId);
    }
  };
}

export default createBackendHttpClient();
