import { QueryClient, defaultShouldDehydrateQuery, isServer } from '@tanstack/react-query';

function makeQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
        retry: (
          failureCount: number,
          err: {
            response: { status: number };
            request: { url: string };
          }
        ) => {
          if (err?.response?.status === 401) {
            return false; // do not retry, trigger error
          }

          // otherwise, restore default
          const defaultRetry = new QueryClient().getDefaultOptions().queries?.retry as number;

          return Number.isSafeInteger(defaultRetry) ? failureCount < (defaultRetry ?? 0) : false;
        }
      },
      dehydrate: {
        // include pending queries in dehydration
        shouldDehydrateQuery: (query) =>
          defaultShouldDehydrateQuery(query) || query.state.status === 'pending'
      }
    }
  });
}

let browserQueryClient: QueryClient | undefined = undefined;

export function getQueryClient() {
  if (isServer) {
    // Server: always make a new query client
    return makeQueryClient();
  } else {
    // Browser: make a new query client if we don't already have one
    // This is very important, so we don't re-make a new client if React
    // suspends during the initial render. This may not be needed if we
    // have a suspense boundary BELOW the creation of the query client
    if (!browserQueryClient) browserQueryClient = makeQueryClient();
    return browserQueryClient;
  }
}
