'use client';
import cookie from 'js-cookie';
import React, { ReactNode, useState } from 'react';
import type { Workspace } from '#/src/types/Workspace';

interface SessionContextType {
  workspaceId: Workspace['id'] | null;
  setWorkspaceId: (workspaceId: Workspace['id'] | null) => void;
}

export const SessionContext = React.createContext<SessionContextType>({
  workspaceId: null,
  setWorkspaceId: (workspaceId) => {
    console.log(workspaceId);
  }
});

export type SessionProviderProps = {
  children: ReactNode | ReactNode[];
  session?: { workspaceId?: Workspace['id'] };
};

const SessionProvider: React.FC<{
  children: ReactNode | ReactNode[];
  session?: { workspaceId: Workspace['id'] };
}> = (props) => {
  const { children, session } = props;

  const [workspaceId, _setWorkspaceId] = useState<Workspace['id'] | null>(
    session?.workspaceId || null
  );

  const setWorkspaceId = (workspaceId: Workspace['id'] | null) => {
    _setWorkspaceId(workspaceId);

    if (workspaceId) {
      cookie.set('workspaceId', workspaceId);
    } else {
      cookie.remove('workspaceId');
    }
  };

  return (
    <SessionContext.Provider
      value={{
        workspaceId,
        setWorkspaceId
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
export { SessionProvider };
