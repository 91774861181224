import { useMutation, useQuery } from '@tanstack/react-query';
import httpClient from '#/src/api/backendHttpClient';

export type IUserInfo = {
  user: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl?: string;
    lastLogin: number;
    createdAt: number;
    updatedAt: number;
    deletedAt?: number;
  };
  isNewUser?: boolean;
};

export const useUserQuery = () =>
  useQuery<IUserInfo>({
    queryKey: ['current-user'],
    queryFn: () => httpClient.get<IUserInfo>('/user/me')
  });

export const useUpdateUserMutation = () =>
  useMutation({
    mutationKey: ['current-user'],
    mutationFn: (userInfo: IUserInfo['user']) => httpClient.put<IUserInfo>('/user/me', userInfo)
  });
